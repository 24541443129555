/** @jsxImportSource @emotion/react */
import { useEffect } from "react"
import { Form, Button, Input, Row, Col, Popconfirm } from "antd"

import { isPassword, isEmail } from "@utils/validation"
import { MediaQuery } from "@utils/style"
import { useMobxStores } from "@stores/stores"

import OnlyNumberInput from "@components/input/OnlyNumberInput"

import userApi from "@api/user"

const initialValues = {
  password: null,
  checkPassword: null,
  phoneNumber: null,
  email: null,
}

export default function MyInfo({ setMyInfoModal }) {
  const [form] = Form.useForm()
  const { userStore, globalStore } = useMobxStores()

  async function updateInfo() {
    // 내정보 수정
    try {
      const data = form?.getFieldValue()

      if (!isPassword(data?.password))
        return globalStore.warningNoti(
          `비밀번호는 영문(대문자 1글자 이상 필수), 숫자, 특수문자를 모두 포함하여야 합니다.`
        )
      if (data?.password !== data?.checkPassword)
        return globalStore.warningNoti(
          "비밀번호가 일치하지 않습니다. 다시 확인해주세요"
        )
      if (!isEmail(data?.email))
        return globalStore.warningNoti("이메일을 다시 확인해주세요")

      await userApi.updateMyInfo(data)
      globalStore.successNoti("내정보가 변경되었습니다.")
      form.setFieldsValue({ ...initialValues })
      setMyInfoModal(false)
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  useEffect(() => {
    if (userStore?.user) {
      form.setFieldsValue({
        ...initialValues,
        phoneNumber: userStore?.user?.phoneNumber || null,
        email: userStore?.user?.email || null,
      })
    }
  }, [])

  return (
    <div css={styles.container}>
      <Form form={form} initialValues={initialValues}>
        <Form.Item label="회원ID">{userStore?.user?.id}</Form.Item>
        <Form.Item name="password" label="변경할 비밀번호">
          <Input.Password placeholder="비밀번호를 입력해주세요." />
        </Form.Item>
        <Form.Item name="checkPassword" label="비밀번호 확인">
          <Input.Password placeholder="비밀번호를 입력해주세요." />
        </Form.Item>
        <Form.Item name="phoneNumber" label="대표 전화번호">
          <OnlyNumberInput placeholder="전화번호를 입력해주세요." />
        </Form.Item>
        <Form.Item name="email" label="이메일">
          <Input placeholder="이메일을 입력해주세요." />
        </Form.Item>
      </Form>
      <Row>
        <Col xs={24}>
          <Popconfirm
            title={"변경 하시겠습니까?"}
            cancelText={"취소"}
            okText={"확인"}
            onConfirm={updateInfo}
          >
            <Button style={{ float: "right", marginTop: 8 }} type="primary">
              변경하기
            </Button>
          </Popconfirm>
        </Col>
      </Row>
    </div>
  )
}

const styles = {
  container: {
    "& label": {
      minWidth: 115,
    },
    "& .ant-form-item": {
      marginBottom: 12,
      [MediaQuery[0]]: {
        marginBottom: 4,
      },
    },
    "& .ant-form-item-label": {
      padding: 0,
    },
  },
}
